import { Controller } from 'stimulus'; 
import $ from 'jquery'

export default class extends Controller {
    static targets = ['content', 'labels']
    connect(){
    }

    retrieveVersion(e){
        const labels = this.labelsTargets
        for (const label of labels) {
            label.classList.remove("linear-gradient")
        }
        console.log(labels.lenght)
        e.currentTarget.classList.add("linear-gradient")
        const id = e.currentTarget.dataset.id
        console.log(id)
        $.ajax({
            method: "get",
            dataType: "json",
            // take currentQuestion id and archived it
            url: `/version/${id}`,
            success: (data) => {
                this.contentTarget.innerHTML = data.content
            },
            error: (data) => {
                console.log("ERROR", data.responseText)
            }
        })
    }

}
