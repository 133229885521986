import { Controller } from 'stimulus'; 

    export default class extends Controller {
        static targets = ["physique", "moral", "modifAvennant","article"]

        connect(){}

        physique(){
            if(this.targets.element.querySelector(".physique-container") == null){
                this.moralTarget.innerHTML = ""
                let PhysiqueDivContainer = document.createElement('div')
                PhysiqueDivContainer.className = "physique-container"
                PhysiqueDivContainer.innerHTML = `
                <label class="string optional" for="temporary_contract_Quels sont les prénom et nom de cette personne ">Quels sont les prénom et nom de cette personne ?</label>
                <div class="input string optional temporary_contract_firstname_alter">
                    <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" placeholder=" Steve" type="text" name="temporary_contract[firstname_alter]" id="temporary_contract_firstname_alter">
                </div>
                <div class="input string optional temporary_contract_lastname_alter">
                    <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" placeholder="Jobs" type="text" name="temporary_contract[lastname_alter]" id="temporary_contract_lastname_alter">
                </div>
                <label class="string optional" for="temporary_contract_Question A2 : Quelle est son adresse ">Question a2 : quelle est son adresse ?</label>
                <div class="input string optional temporary_contract_address_alter"><input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" placeholder="55 Rue du Faubourg Saint-Honoré, 75008 Paris" type="text" name="temporary_contract[address_alter]" id="temporary_contract_address_alter"></div>
                `
                this.physiqueTarget.appendChild(PhysiqueDivContainer)
            }
        }
        moral(){
            if(this.targets.element.querySelector(".moral-container") == null){

                this.physiqueTarget.innerHTML = ""
                let MoralDivContainer = document.createElement('div')
                MoralDivContainer.className = "moral-container"
                MoralDivContainer.innerHTML = `
                <label class="string optional" for="temporary_contract_Précisez le SIREN (9 chiffres) de cette entreprise :">Précisez le siren (9 chiffres) de cette entreprise :</label>
                <div class="input integer optional temporary_contract_siret_alter"><input class="numeric integer optional w-full poppins block bg-transparent border-b-707 font-888 appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" placeholder="888264330" type="number" step="1" name="temporary_contract[siret_alter]" id="temporary_contract_siret_alter"></div>
                `
                this.moralTarget.appendChild(MoralDivContainer)
                this.siret()
            }
        }
        modifAvenant(){
            if(this.targets.element.querySelector(".modif-avenant-container") == null){

                
                let modifAvennantontainer = document.createElement('div')
                modifAvennantontainer.className = "modif-avenant-container"
                modifAvennantontainer.innerHTML = `
                <div class="flex flex-col md:flex-row items-center items-center justify-between w-full mt-4">
                <label class="string optional mb-8" for="temporary_contract_A quelle date le contrat d’origine a-t-il été signé ">A quelle date le contrat d’origine a-t-il été signé ?</label>
                <input class="poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" type="date" name="temporary_contract[date_previous_avenantN]" id="temporary_contract_date_previous_avenantN">
                </div>
                `
                this.modifAvennantTarget.appendChild(modifAvennantontainer)
            }
        }
        nonModifAvenant(){
            this.modifAvennantTarget.innerHTML = ""
        }

        addArticle(){
            let addArticleContainer = document.createElement('div')
            addArticleContainer.className = "add-article-container p-4 rounded-xl bg-white bg-opacity-10 mt-4"
            addArticleContainer.innerHTML = `
                <label class="string optional" for="temporary_contract_Quel sera le titre de ce nouvel article ">Quel sera le titre de ce nouvel article ?</label><br>
                <input type="text" class="w-full poppins block bg-dark-333 border-b-707 font-888 outline-none focus:border-purple-400" name="temporary_contract[article_added_titleP][]" id="temporary_contract_article_added_titleP_" value="" multiple="multiple"><br>

                <label class="string optional" for="temporary_contract_Quel en sera le contenu ">Quel en sera le contenu ?</label><br>
                <textarea rows="6" name="temporary_contract[article_added_contentP][]" id="temporary_contract_article_added_contentP_" value="" multiple="multiple" class="w-full poppins block bg-dark-333 border-b-707 font-888 outline-none focus:border-purple-400 mb-8 mt-2" placeholder="création d’une plateforme juridique collaborative"></textarea><br><br>
                <input type="button" value="X" class="start-template-btn cursor-pointer w-8 h-8 poppins dark-f2f text-center" data-action="click->avenant#removeArticle">
            `
            this.articleTarget.appendChild(addArticleContainer)
        }

        modifArticle(){
            let modifArticleContainer = document.createElement('div')
            modifArticleContainer.className = "modif-article-container p-4 rounded-xl bg-white bg-opacity-10 mt-4"
            modifArticleContainer.innerHTML = `
            <label class="string optional" for="temporary_contract_Précisez le titre de l’article à modifier :">Précisez le titre de l’article à modifier :</label><br>
            <input type="text" class="w-full poppins block bg-dark-333 border-b-707 font-888 outline-none focus:border-purple-400" name="temporary_contract[article_amended_referenceM][]" id="temporary_contract_article_amended_referenceM_" value="" multiple="multiple"><br><br>

            <label class="string optional" for="temporary_contract_Quelle en sera sa nouvelle rédaction ? (avec son titre s’il est lui aussi modifié)">Quelle en sera sa nouvelle rédaction ? (avec son titre s’il est lui aussi modifié)</label><br>
            <textarea rows="6" name="temporary_contract[article_amended_contentM][]" id="temporary_contract_article_amended_contentM_" value="" multiple="multiple" class="w-full poppins block bg-dark-333 border-b-707 font-888 outline-none focus:border-purple-400 mb-8 mt-2" placeholder="création d’une plateforme juridique collaborative"></textarea><br><br>
            <input type="button" value="X" class="start-template-btn cursor-pointer w-8 h-8 poppins dark-f2f text-center" data-action="click->avenant#removeArticle">
            `
            this.articleTarget.appendChild(modifArticleContainer)
        }
        removeArticle(e){
            e.path[1].remove()
        }
    }





//     <div class="form-inputs text-p" data-controller="">
//             <!-- Question 1 -->
//             <label class="string optional" for="temporary_contract_Question 1 : Qui était l’autre personne signataire du contrat d’origine ">Question 1 : qui était l’autre personne signataire du contrat d’origine ?</label><br>
//             <label for="temporary_contract_is_physique">A : Une personne physique</label>
//             <input type="radio" value="PHYSIQUE" name="temporary_contract[version0]" id="temporary_contract_version0_physique"><br>
//             <label for="temporary_contract_is_moral">B : Une entreprise</label>
//             <input type="radio" value="MORALE" name="temporary_contract[version0]" id="temporary_contract_version0_morale"><br><br>

//             <label class="string optional" for="temporary_contract_SI 1/A : Question A1 : Quels sont les prénom et nom de cette personne ">Si 1/a : question a1 : quels sont les prénom et nom de cette personne ?</label>
//             <div class="input string optional temporary_contract_lastname_alter"><input class="string optional" placeholder="ex. : Steve Jobs" type="text" name="temporary_contract[fullname_alter]" id="temporary_contract_fullname_alter"></div><br>
//             <label class="string optional" for="temporary_contract_Question A2 : Quelle est son adresse ">Question a2 : quelle est son adresse ?</label>
//             <div class="input string optional temporary_contract_address_alter"><input class="string optional" placeholder="ex. : 55 Rue du Faubourg Saint-Honoré, 75008 Paris" type="text" name="temporary_contract[address_alter]" id="temporary_contract_address_alter"></div><br>
            

 
//             <!-- Question 2 -->
//             <label class="string optional" for="temporary_contract_Question 2 : A quelle date le contrat d’origine a-t-il été signé ">Question 2 : a quelle date le contrat d’origine a-t-il été signé ?</label>
//             <div class="input date optional temporary_contract_date_previous_contract"><select id="temporary_contract_date_previous_contract_1i" name="temporary_contract[date_previous_contract(1i)]" class="date optional">
// <option value="2016">2016</option>
// <option value="2017">2017</option>
// <option value="2018">2018</option>
// <option value="2019">2019</option>
// <option value="2020">2020</option>
// <option value="2021" selected="selected">2021</option>
// <option value="2022">2022</option>
// <option value="2023">2023</option>
// <option value="2024">2024</option>
// <option value="2025">2025</option>
// <option value="2026">2026</option>
// </select>
// <select id="temporary_contract_date_previous_contract_2i" name="temporary_contract[date_previous_contract(2i)]" class="date optional">
// <option value="1">January</option>
// <option value="2">February</option>
// <option value="3" selected="selected">March</option>
// <option value="4">April</option>
// <option value="5">May</option>
// <option value="6">June</option>
// <option value="7">July</option>
// <option value="8">August</option>
// <option value="9">September</option>
// <option value="10">October</option>
// <option value="11">November</option>
// <option value="12">December</option>
// </select>
// <select id="temporary_contract_date_previous_contract_3i" name="temporary_contract[date_previous_contract(3i)]" class="date optional">
// <option value="1">1</option>
// <option value="2">2</option>
// <option value="3">3</option>
// <option value="4">4</option>
// <option value="5" selected="selected">5</option>
// <option value="6">6</option>
// <option value="7">7</option>
// <option value="8">8</option>
// <option value="9">9</option>
// <option value="10">10</option>
// <option value="11">11</option>
// <option value="12">12</option>
// <option value="13">13</option>
// <option value="14">14</option>
// <option value="15">15</option>
// <option value="16">16</option>
// <option value="17">17</option>
// <option value="18">18</option>
// <option value="19">19</option>
// <option value="20">20</option>
// <option value="21">21</option>
// <option value="22">22</option>
// <option value="23">23</option>
// <option value="24">24</option>
// <option value="25">25</option>
// <option value="26">26</option>
// <option value="27">27</option>
// <option value="28">28</option>
// <option value="29">29</option>
// <option value="30">30</option>
// <option value="31">31</option>
// </select>
// </div><br>
            
//             <!-- Question 3 -->
//             <label class="string optional" for="temporary_contract_Question 3 : Quel était son titre (en en-tête) ">Question 3 : quel était son titre (en en-tête) ?</label>
//             <div class="input string optional temporary_contract_title_previous_contract"><input class="string optional" placeholder="ex. : Contrat de prestation de service" type="text" name="temporary_contract[title_previous_contract]" id="temporary_contract_title_previous_contract"></div><br><br>
            
//             <!-- Question 4 -->
//             <label class="string optional" for="temporary_contract_Question 4 : Ce contrat d’origine a-t-il déjà été modifié par un (ou plusieurs) avenant(s) précédent(s) ">Question 4 : ce contrat d’origine a-t-il déjà été modifié par un (ou plusieurs) avenant(s) précédent(s) ?</label><br>
//             <label for="temporary_contract_yes">oui</label>
//             <input type="radio" value="B1" name="temporary_contract[version1]" id="temporary_contract_version1_b1"><br>
//             <label for="temporary_contract_no">non</label>
//             <input type="radio" value="budweiser" name="temporary_contract[version1]" id="temporary_contract_version1_budweiser"><br><br>
            
//             <label class="string optional" for="temporary_contract_SI 4/A : Question 4bis :  De quand date cet avenant ">Si 4/a : question 4bis :  de quand date cet avenant ?</label>
//             <div class="input date optional temporary_contract_date_previous_avenantN"><select id="temporary_contract_date_previous_avenantN_1i" name="temporary_contract[date_previous_avenantN(1i)]" class="date optional">
// <option value="2016">2016</option>
// <option value="2017">2017</option>
// <option value="2018">2018</option>
// <option value="2019">2019</option>
// <option value="2020">2020</option>
// <option value="2021" selected="selected">2021</option>
// <option value="2022">2022</option>
// <option value="2023">2023</option>
// <option value="2024">2024</option>
// <option value="2025">2025</option>
// <option value="2026">2026</option>
// </select>
// <select id="temporary_contract_date_previous_avenantN_2i" name="temporary_contract[date_previous_avenantN(2i)]" class="date optional">
// <option value="1">January</option>
// <option value="2">February</option>
// <option value="3" selected="selected">March</option>
// <option value="4">April</option>
// <option value="5">May</option>
// <option value="6">June</option>
// <option value="7">July</option>
// <option value="8">August</option>
// <option value="9">September</option>
// <option value="10">October</option>
// <option value="11">November</option>
// <option value="12">December</option>
// </select>
// <select id="temporary_contract_date_previous_avenantN_3i" name="temporary_contract[date_previous_avenantN(3i)]" class="date optional">
// <option value="1">1</option>
// <option value="2">2</option>
// <option value="3">3</option>
// <option value="4">4</option>
// <option value="5" selected="selected">5</option>
// <option value="6">6</option>
// <option value="7">7</option>
// <option value="8">8</option>
// <option value="9">9</option>
// <option value="10">10</option>
// <option value="11">11</option>
// <option value="12">12</option>
// <option value="13">13</option>
// <option value="14">14</option>
// <option value="15">15</option>
// <option value="16">16</option>
// <option value="17">17</option>
// <option value="18">18</option>
// <option value="19">19</option>
// <option value="20">20</option>
// <option value="21">21</option>
// <option value="22">22</option>
// <option value="23">23</option>
// <option value="24">24</option>
// <option value="25">25</option>
// <option value="26">26</option>
// <option value="27">27</option>
// <option value="28">28</option>
// <option value="29">29</option>
// <option value="30">30</option>
// <option value="31">31</option>
// </select>
// </div><br>

//             <p>Proposer “Ajouter un autre avenant intermédiaire”</p>
//             <p>Question 4An : De quand date cet avenant ?  -&gt;  answer4An = date_previous_avenantN</p>
//             <p>*n</p><br><br>


//             <!-- Question 5 -->
//             <label class="string optional" for="temporary_contract_Question 5 : Souhaitez-vous modifier un article au contrat d’origine ou y ajouter un nouvel article ">Question 5 : souhaitez-vous modifier un article au contrat d’origine ou y ajouter un nouvel article ?</label><br>
//             <label for="temporary_contract_is_physique">A : Modifier un article</label>
//             <input type="radio" value="B2" name="temporary_contract[version2]" id="temporary_contract_version2_b2"><br>
//             <label for="temporary_contract_is_moral">B : Ajouter un article</label>
//             <input type="radio" value="B3" name="temporary_contract[version2]" id="temporary_contract_version2_b3"><br><br>

//             <label class="string optional" for="temporary_contract_SI 5/A : Question 5A1 : Précisez le titre de l’article à modifier :">Si 5/a : question 5a1 : précisez le titre de l’article à modifier :</label><br>
//             <input type="text" name="temporary_contract[article_amended_referenceM][]" id="temporary_contract_article_amended_referenceM_" value="" multiple="multiple"><br>

//             <label class="string optional" for="temporary_contract_Question 5A2 : Quelle en sera sa nouvelle rédaction ? (avec son titre s’il est lui aussi modifié)">Question 5a2 : quelle en sera sa nouvelle rédaction ? (avec son titre s’il est lui aussi modifié)</label> <br>
//             <input type="text" name="temporary_contract[article_amended_contentM][]" id="temporary_contract_article_amended_contentM_" value="" multiple="multiple"><br>
//             <p>Proposer “Modifier un autre article”</p>
//             <p>Proposer “Ajouter un autre article”</p><br><br>

//             <!-- test multi input -->
//             <input type="text" name="temporary_contract[article_amended_referenceM][]" id="temporary_contract_article_amended_referenceM_" value="" multiple="multiple"><br>
//             <input type="text" name="temporary_contract[article_amended_contentM][]" id="temporary_contract_article_amended_contentM_" value="" multiple="multiple"><br>
//             <!------------------------>

//             <label class="string optional" for="temporary_contract_SI 5/B : Question 5B1 : Quel sera le titre de ce nouvel article ">Si 5/b : question 5b1 : quel sera le titre de ce nouvel article ?</label>
//             <input type="text" name="temporary_contract[article_added_titleP][]" id="temporary_contract_article_added_titleP_" value="" multiple="multiple"><br><br>

//             <label class="string optional" for="temporary_contract_Question 5B2 : Quel en sera le contenu ">Question 5b2 : quel en sera le contenu ?</label><br>
//             <input type="text" name="temporary_contract[article_added_contentP][]" id="temporary_contract_article_added_contentP_" value="" multiple="multiple"><br>
//             <p>Proposer “Modifier un autre article”</p>
//             <p>Proposer “Ajouter un autre article”</p>
//             <!-- Question 6 -->

            
//         </div>













// <div class="form-inputs text-p" data-controller>
// <!-- Question 1 -->
// <%= f.label 'Question 1 : Qui était l’autre personne signataire du contrat d’origine ?' %><br>
// <%= f.label 'is_physique', 'A : Une personne physique' %>
// <%= f.radio_button :version0, "PHYSIQUE" %><br>
// <%= f.label 'is_moral', 'B : Une entreprise' %>
// <%= f.radio_button :version0, "MORALE" %><br><br>

// <%= f.label 'SI 1/A : Question A1 : Quels sont les prénom et nom de cette personne ?' %>
// <%= f.input :fullname_alter, label: false, placeholder: "ex. : Steve Jobs" %><br>
// <%= f.label 'Question A2 : Quelle est son adresse ?' %>
// <%= f.input :address_alter, label: false, placeholder: "ex. : 55 Rue du Faubourg Saint-Honoré, 75008 Paris" %><br>


// <%
// =begin%>
// <%= f.label 'Si 1/B : Précisez le SIREN (9 chiffres) de cette entreprise :' %>
// <%= f.input :siret_alter, label: false, placeholder: "ex. : “888264330”" %><br>
// <p>Je renseignerai ces informations directement dans mon projet de contrat.</p><br><br> 
// <%
// =end%>

// <!-- Question 2 -->
// <%= f.label 'Question 2 : A quelle date le contrat d’origine a-t-il été signé ?' %>
// <%= f.input :date_previous_contract, label: false %><br>

// <!-- Question 3 -->
// <%= f.label 'Question 3 : Quel était son titre (en en-tête) ?' %>
// <%= f.input :title_previous_contract, label: false, placeholder: "ex. : Contrat de prestation de service" %><br><br>

// <!-- Question 4 -->
// <%= f.label 'Question 4 : Ce contrat d’origine a-t-il déjà été modifié par un (ou plusieurs) avenant(s) précédent(s) ?' %><br>
// <%= f.label 'yes', 'oui' %>
// <%= f.radio_button :version1, "B1" %><br>
// <%= f.label 'no', 'non' %>
// <%= f.radio_button :version1, "budweiser" %><br><br>

// <%= f.label 'SI 4/A : Question 4bis :  De quand date cet avenant ?' %>
// <%= f.input :date_previous_avenantN, label: false %><br>

// <p>Proposer “Ajouter un autre avenant intermédiaire”</p>
// <p>Question 4An : De quand date cet avenant ?  ->  answer4An = date_previous_avenantN</p>
// <p>*n</p><br><br>


// <!-- Question 5 -->
// <%= f.label 'Question 5 : Souhaitez-vous modifier un article au contrat d’origine ou y ajouter un nouvel article ?' %><br>
// <%= f.label 'is_physique', 'A : Modifier un article' %>
// <%= f.radio_button :version2, "B2" %><br>
// <%= f.label 'is_moral', 'B : Ajouter un article' %>
// <%= f.radio_button :version2, "B3" %><br><br>

// <%= f.label 'SI 5/A : Question 5A1 : Précisez le titre de l’article à modifier :' %><br>
// <%= text_field_tag "temporary_contract[article_amended_referenceM][]", "", multiple: true %><br>

// <%= f.label 'Question 5A2 : Quelle en sera sa nouvelle rédaction ? (avec son titre s’il est lui aussi modifié)' %> <br>
// <%= text_field_tag "temporary_contract[article_amended_contentM][]", "", multiple: true %><br>
// <p>Proposer “Modifier un autre article”</p>
// <p>Proposer “Ajouter un autre article”</p><br><br>

// <!-- test multi input -->
// <%= text_field_tag "temporary_contract[article_amended_referenceM][]", "", multiple: true %><br>
// <%= text_field_tag "temporary_contract[article_amended_contentM][]", "", multiple: true %><br>
// <!------------------------>

// <%= f.label 'SI 5/B : Question 5B1 : Quel sera le titre de ce nouvel article ?' %>
// <%= text_field_tag "temporary_contract[article_added_titleP][]", "", multiple: true %><br><br>

// <%= f.label 'Question 5B2 : Quel en sera le contenu ?' %><br>
// <%= text_field_tag "temporary_contract[article_added_contentP][]", "", multiple: true %><br>
// <p>Proposer “Modifier un autre article”</p>
// <p>Proposer “Ajouter un autre article”</p>
// <!-- Question 6 -->


// </div> 