import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["categoryItems", "createSociety","manageSociety","manageActivity","recruit","letter"]

    connect() {
    }

    toggleAll(event) {
      this.displayElements(this.categoryItemsTargets, event.currentTarget)
    }

    toggleCreateSociety(event) {
      this.displayElements(this.createSocietyTargets, event.currentTarget)
    }

    toggleManageSociety(event) {
      this.displayElements(this.manageSocietyTargets, event.currentTarget)
    }

    toggleManageActivity(event) {
      this.displayElements(this.manageActivityTargets, event.currentTarget)
    }

    toggleRecruit(event) {
      this.displayElements(this.recruitTargets, event.currentTarget)
    }

    toggleLetter(event) {
      this.displayElements(this.letterTargets, event.currentTarget)
    }


    toggleSwitchedCategoryButton(element) {
      document.querySelector('.clicked-category').classList.remove('clicked-category')
      element.classList.add('clicked-category')
    }

    displayElements(elementsToDisplay, categoryButton) {
      this.toggleSwitchedCategoryButton(categoryButton)
      const categoryItems = this.categoryItemsTargets
      if (categoryItems.length != elementsToDisplay.lenght) {
        for (const item of categoryItems) {
          item.classList.add("hidden");
        }
      }
      if (elementsToDisplay.lenght != 0) {
        for (const element of elementsToDisplay) {
          element.classList.remove("hidden");
        }
      }
    }

}
