import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['questionInput', 'contractInput', 'content']
    connect(){
    }

    showInput(e) {
        this.targets.find(e.target.dataset.value).classList.remove('hidden')
    }
    hideInput(e) {
        let currentTarget = this.targets.find(e.target.dataset.value)
        currentTarget.classList.add('hidden')
        currentTarget.firstElementChild.selectedIndex = 0
    }

    updateContent(e) {
        this.contentTarget.innerText = e.target.querySelector('p[data-content]').dataset.content
    }

}
