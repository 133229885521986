import { Controller } from 'stimulus'; 

    export default class extends Controller {
        static targets = ["convention"]

        connect(){}


        addConvention(){
            let addConventionContainer = document.createElement('div')
            addConventionContainer.className = "add-convention-container add-article-container p-4 rounded-xl bg-white bg-opacity-10 mt-4"
            addConventionContainer.innerHTML = `
                <label class="string optional" for="temporary_contract_Définissez une nouvelle convention">Dénommez cette nouvelle convention</label><br>
                <input type="text" class="w-full poppins block bg-dark-333 border-b-707 font-888 outline-none focus:border-purple-400" name="temporary_contract[article_added_titleP][]" id="article_added_titleP_" value="" multiple="multiple"><br><br>
                <input type="button" value="X" class="start-template-btn cursor-pointer w-8 h-8 poppins dark-f2f text-center" data-action="click->cdi#removeConvention">
            `
            this.conventionTarget.appendChild(addConventionContainer)
        }

        removeConvention(e){
            e.path[1].remove()
        }
    }
