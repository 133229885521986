Trix.config.textAttributes.heading = {
    tagName: "underline",
    terminal: true,
    breakOnReturn: true,
    group: false
}
// Trix.config.blockAttributes.default.tagName = "p"


Trix.config.blockAttributes.centering = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false
    
}

Trix.config.blockAttributes.righting = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false
}

Trix.config.blockAttributes.default.tagName = "p"
Trix.config.blockAttributes.default.breakOnReturn = true
