import { Controller } from 'stimulus'; 

    export default class extends Controller {
        static targets = ['physique', 'moral', 'manualCocon', 'siret', "moraleInput","physiqueInput"]
        connect(){
            this.moraleInputTarget.checked = true
            this.moral();
        }
        
        physique(){
            if(this.targets.element.querySelector(".physique-container") == null){
                this.moralTarget.innerHTML = ""
                let PhysiqueDivContainer = document.createElement('div')
                PhysiqueDivContainer.className = "physique-container"
                PhysiqueDivContainer.innerHTML = `
                <div class="input string optional temporary_contract_firstname_alter">
                <label class="string optional" for="temporary_contract_firstname_alter">Prénom</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mt-1 mb-2 outline-none border-b-2 focus:border-purple-400" placeholder="Ex : Steve" type="text" name="temporary_contract[firstname_alter]"></div>
                <div class="input string optional temporary_contract_lastname_alter">
                <label class="string optional" for="temporary_contract_lastname_alter">Nom</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mt-1 mb-2 outline-none border-b-2 focus:border-purple-400" placeholder="Ex : Jobs" type="text" name="temporary_contract[lastname_alter]"></div>
                <div class="input string optional temporary_contract_address_alter">
                <label class="string optional" for="temporary_contract_address_alter">Adresse</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mt-1 mb-2 outline-none border-b-2 focus:border-purple-400" placeholder="Ex : 3 rue des jardins, 88255 Boulogne" type="text" name="temporary_contract[address_alter]">
                </div>
                <p class="mt-4">Décrivez succinctement le projet dont vous souhaitez discuter avec votre cocontractant</p>
                <textarea name="temporary_contract[project_description]" class="w-full poppins block bg-dark-333 border-b-707 font-888 outline-none focus:border-purple-400 mb-8 mt-2" placeholder="création d’une plateforme juridique collaborative"></textarea></div></div>
                `
                this.physiqueTarget.appendChild(PhysiqueDivContainer)
            }
        }

        siret(){
            if(this.targets.element.querySelector(".siret-container") == null){
                this.manualCoconTarget.innerHTML = ""
                let siretDiv = document.createElement('div')
                siretDiv.className = "siret-container"
                siretDiv.innerHTML = `
                <p>Afin de faciliter la création de votre contrat, vous pouvez renseigner le SIREN de votre cocontractant. Ses mentions sociales sont alors automatiquement renseignées ! C'est rapide et efficace !</p><br>
                <label class="integer optional" for="temporary_contract_siret_alter">SIREN (9 chiffres)</label>
                <input class="w-full poppins block ml-0 md:ml-2 bg-transparent border-b-707 font-888 numeric integer optional outline-none border-b-2 focus:border-purple-400" type="number" step="1" name="temporary_contract[siret_alter]"><br>
                    <p>Vous préférez renseigner plus tard, ou manuellement, les informations sur votre cocontractant ?</p>
                <input type="button" class="dark-f2f start-template-btn px-4 py-2 mb-2 md:mb-0 mt-2" value="Renseigner manuellement les informations du co-contractant" data-action="click->accordDeConfidentialite#manualCocon">
                `
                this.siretTarget.appendChild(siretDiv)
            }
        }

        manualCocon(){
            if(this.targets.element.querySelector(".cocon-container") == null){
                this.siretTarget.innerHTML = ""
                let manualCoconDiv = document.createElement('div')
                manualCoconDiv.className = "cocon-container"
                manualCoconDiv.innerHTML = `
                <div class="input string optional temporary_contract_name_alter">
                <label class="string optional" for="temporary_contract_name_alter">Nom de la société</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" type="text" name="temporary_contract[name_alter]">
                </div>
                <div class="input string optional temporary_contract_corporate_form_alter">
                <label class="string optional" for="temporary_contract_corporate_form_alter">Forme sociale</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" type="text" name="temporary_contract[corporate_form_alter]">
                </div>
                <div class="input integer optional temporary_contract_capitale_alter">
                <label class="integer optional" for="temporary_contract_capitale_alter">Montant du capital social</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" type="number" step="1" name="temporary_contract[capitale_alter]">
                </div>
                <div class="input string optional temporary_contract_headquarter_alter">
                <label class="string optional" for="temporary_contract_headquarter_alter">Adresse du siège social</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" type="text" name="temporary_contract[headquarter_alter]">
                </div>
                <div class="input integer optional temporary_contract_num_rcs_alter">
                <label class="integer optional" for="temporary_contract_num_rcs_alter" type="number" step="1" name="temporary_contract[num_rcs_alter]">Numéro de RCS</label>
                <input class="numeric integer w-full poppins block bg-transparent border-b-707 font-888 optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400">
                </div>
                <div class="input string optional temporary_contract_city_registry_alter">
                <label class="string optional" for="temporary_contract_city_registry_alter">Ville du RCS</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" type="text" name="temporary_contract[city_registry_alter]">
                </div>
                <div class="input string optional temporary_contract_rp_first_alter">
                <label class="string optional" for="temporary_contract_rp_first_alter">Prénom du représentant</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" type="text" name="temporary_contract[rp_first_alter]">
                </div>
                <div class="input string optional temporary_contract_rp_last_alter">
                <label class="string optional" for="temporary_contract_rp_last_alter">Nom du représentant</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" type="text" name="temporary_contract[rp_last_alter]">
                </div>
                <div class="input string optional temporary_contract_rp_last_alter">
                <label class="string optional" for="temporary_contract_rp_title_alter">Fonction du représentant</label>
                <input class="w-full poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" type="text" name="temporary_contract[rp_title_alter]">
                </div>
    
                <input type="button" class="dark-f2f start-template-btn px-4 py-2 mb-2 md:mb-0 mt-2" value="Repasser sur la Siren" data-action="click->accordDeConfidentialite#siret">
                `
                this.manualCoconTarget.appendChild(manualCoconDiv)
            }
        }

        moral(){
            if(this.targets.element.querySelector(".moral-container") == null){

                this.physiqueTarget.innerHTML = ""
                let MoralDivContainer = document.createElement('div')
                MoralDivContainer.className = "moral-container"
                MoralDivContainer.innerHTML = `
                    <div class="input integer optional temporary_contract_siret_alter">
                    
                    <div data-target="accordDeConfidentialite.siret" id="siret-div"></div>
                    <div data-target="accordDeConfidentialite.manualCocon" id="manualCocon-div"></div>
                    <div>
                        <label>
                            Décrivez succinctement le projet dont vous souhaitez discuter avec votre cocontractant
                        </label>
                        <textarea name="temporary_contract[project_description]" class="mt-2 bg-transparent border-707 rounded-xl px-4 py-2 w-full font-888 outline-none" placeholder="ex. : création d’une plateforme contractuelle collaborative"></textarea>
                    </div>
                </div>
                `
                this.moralTarget.appendChild(MoralDivContainer)
                this.siret()
            }
        }
    }