import { Controller } from 'stimulus';
import $, { merge } from 'jquery'
let backup


export default class extends Controller {
    static targets = ['actionText', "toggleContractCard", "preview","newCycleBtn", "finalizedBtn", "cardsContainer"]

    connect() {
        this.cardToggling()
        document.querySelector("trix-editor").contentEditable = false
        document.querySelector("trix-toolbar").remove()
        this.checkNoModifiedArticle(this.cardsContainerTarget.firstElementChild)
    }

    findHtmlForArticle(e){
        const regex = /\<strong\>(.*?)\<\/strong\>(\s*(\<br\s*\>){0,20})/gim
        let current_btn
        let card
        if(e.target !== undefined){
            current_btn = (e.target.classList.contains('choose')) ? current_btn = e.target : e.target.parentElement
            card = e.target.closest('div[data-target="merge.toggleContractCard"]')
        } else if (e.classList.contains('choose')){
            current_btn = e
            card = e.closest('div[data-target="merge.toggleContractCard"]')
        }

        if(card.previousElementSibling == undefined || current_btn.classList.contains('thumbs') ) {
            let compteur = parseInt(current_btn.dataset.index)
            $.ajax({
                method: "get",
                dataType: "json",
                url:  `/versioning/${current_btn.dataset.id}/find_html_for_article`,
                data: {index: compteur},
                success:(data) => {
                    if(current_btn.classList.contains('accept')){
                        this.actionTextTarget.editor.insertHTML(data.content_contrib)
                        console.log("contrib", data.content_contrib)
                        // retourne un objet avec le strong et nombre de br

                        //  apres l'ajout regarder a chaque strong si il y a rajout de br si oui supprimer
                    }else if(current_btn.classList.contains('refuse')){
                        this.actionTextTarget.editor.insertHTML(data.content_master)
                        console.log("master", data.content_master)
                        //  apres l'ajout regarder a chaque strong si il y a rajout de br si oui supprimer
                    }


                    let strongs = data.content_contrib.match(regex)
                    let actionTextStrongs = this.actionTextTarget.querySelectorAll('strong')
                    let editor = this.actionTextTarget
                    let StrongsObject = []

                    if(strongs != null){
                        strongs.forEach(e => {
                            if(e.match(/<br>/g)){
                                StrongsObject.push({
                                    strong: e.split('</strong>')[0] + '</strong>',
                                    brLength: e.match(/<br>/g).length
                                })
                            }
                        })

                        //Rechercher tout les string dans le front et trouver le correspondant 
                        let correspondingStrongs = []
                        for (let index = 0; index < actionTextStrongs.length; index++) {
                            for(let j = 0; j < StrongsObject.length; j++){
                                if(actionTextStrongs[index].outerHTML == StrongsObject[j].strong){
                                    correspondingStrongs.push({
                                        backStrong: StrongsObject[j].strong,
                                        frontStrong: actionTextStrongs[index],
                                        lengthBrBackStrong: StrongsObject[j].brLength,
                                        lengthBrFrontStrong: 0
                                    })
                                }
                            }
                        }
                        


                        correspondingStrongs.forEach( correspondingStrong => {
                            
                            let currentElementNumberNode = Array.from(correspondingStrong.frontStrong.parentElement.children).indexOf(correspondingStrong.frontStrong) 
                            for(let i = currentElementNumberNode + 1; i < correspondingStrong.frontStrong.parentElement.children.length ; i++){
                                if(correspondingStrong.frontStrong.parentElement.children[i].outerHTML == '<br>'){
                                    correspondingStrong.lengthBrFrontStrong++
                                }
                            }
                            
                            if(correspondingStrong.lengthBrFrontStrong > correspondingStrong.lengthBrBackStrong ){
                                while(correspondingStrong.lengthBrFrontStrong > correspondingStrong.lengthBrBackStrong ){

                                    if(correspondingStrong.frontStrong.parentElement.children[currentElementNumberNode].nextElementSibling.outerHTML == '<br>'){
    
                                        correspondingStrong.frontStrong.parentElement.children[currentElementNumberNode].nextElementSibling.remove()
                                        correspondingStrong.lengthBrFrontStrong--
                            
                                    }else if(correspondingStrong.frontStrong.parentElement.children[currentElementNumberNode].nextElementSibling.nextElementSibling.outerHTML == '<br>') {
                            
                                        correspondingStrong.frontStrong.parentElement.children[currentElementNumberNode].nextElementSibling.nextElementSibling.remove()
                                        correspondingStrong.lengthBrFrontStrong--
                            
                                    }else {
                                        break
                                    }
                                }
                            }
                        })
                    }


                    this.closeCard(this.cardsContainerTarget)
                    this.checkIfCardsExist()
                },
                error: (data) => {
                }
            })

        }

    }

    modifyHtmlForArticle(e){
        let current_btn = e.target.parentElement
        if(e.target.classList.contains('choose')){
            current_btn = e.target
        }
        let compteur = parseInt(current_btn.dataset.index)
        this.backup = current_btn.parentElement.parentElement.parentElement.querySelector('.diff').parentElement.parentElement.cloneNode(true)
        const diff = current_btn.parentElement.parentElement.parentElement.querySelector('.diff').parentElement

        $.ajax({
            method: "get",
            dataType: "json",
            url:  `/versioning/${current_btn.dataset.id}/find_html_for_article`,
            data: {index: compteur},
            success:(data) => {

                diff.contentEditable = true
                diff.insertAdjacentHTML('afterend', '<button class="px-4 py-2 mx-2 bg-red-500" data-action="click->merge#backupCard">retour</button>');
                diff.insertAdjacentHTML('afterend', '<button class="px-4 py-2 mx-2 bg-green-500" data-action="click->merge#pushModificationForArticle">oui</button>');
                !diff.parentElement.parentElement.querySelector('.fa-trash-alt') ? diff.innerHTML = data.content_contrib : diff.innerHTML = data.content_master
                let a = diff.parentElement.querySelectorAll('.choose')
                let b = Array.from(a)
                b.forEach(element => element.classList.add('hidden'));

            },
            error: (data) => {
            }
        })
    }

    pushModificationForArticle(e){
        this.actionTextTarget.editor.insertHTML(e.target.previousElementSibling.innerHTML)
        this.closeCard(e.path[2])

    }

    togglePreview(e) {
        if(this.previewTarget.classList.contains('hidden')){
            this.previewTarget.classList.remove('hidden')
        }else{
            this.previewTarget.classList.add('hidden')
        }
    }

    cardToggling(e) {
        const cardToggle = (card) => {
            if(card.querySelector("#div-card").classList.contains('hidden')){
                let cardHeader = card.querySelector('#card-header')
                cardHeader.classList.remove('rounded-b-3xl')
                cardHeader.classList.add('border-2', 'border-solid', 'border-b-0', "border-dark-f2f")
                card.querySelector("#div-card").classList.remove('hidden')
                document.querySelector('.initialpreview').querySelectorAll('.dark-AD6').forEach(e => {
                    e.classList.remove('dark-AD6')
                })
                let title = card.querySelector('.title-search').innerText.toUpperCase()
                let array = Array.from(document.querySelector('.initialpreview').firstElementChild.children);
                let articleTitle;
                array.forEach(e => {
                if(e.innerText.includes(title)){
                    articleTitle = e;
                }
                });

                articleTitle.scrollIntoView();
                articleTitle.classList.add('dark-AD6')
            }
        }

        this.toggleContractCardTargets.forEach(card => {
            if(card.previousElementSibling != undefined){
                // card.classList.add('text-gray-400', 'bg-gray-100')
            }
            card.addEventListener("click", (f) => {
                if(!f.target.classList.contains('choose') && !f.target.classList.contains('icon')){
                    if(card.previousElementSibling == undefined){
                        cardToggle(card)
                    }else if(card.previousElementSibling.querySelector("#div-card") == undefined){
                        cardToggle(card)
                    }
                }
            })
        })
    }

    closeModal(e){
        this.previewTarget.classList.add('hidden')
    }

    closeCard(card){
        let cardHeader = card.querySelector('#card-header')
        if(cardHeader.parentElement.classList.contains('hidden')){
            cardHeader.parentElement.remove()
            this.checkNoModifiedArticle(this.cardsContainerTarget.firstElementChild)
        }else {
            cardHeader.parentElement.classList.add('card-animation')
            // wait 1s and remove
            setTimeout(() => {
                cardHeader.parentElement.remove()
                this.checkNoModifiedArticle(this.cardsContainerTarget.firstElementChild)
            }, 800)
        }
    }

    backupCard(e){
        e.target.previousElementSibling.previousElementSibling.parentElement.replaceWith(this.backup)
    }

    checkIfCardsExist(e){
        let cardLength = this.cardsContainerTarget.children.length - 1
        if(cardLength == 0){
            this.enableSubmitesBtns()
        }
    }

    enableSubmitesBtns(e){
        // this.
        document.querySelectorAll(".merge-btn").forEach( e => {
          e.disabled = false
        });
    }

    checkNoModifiedArticle(e){
        if (e && e.querySelector('#unchanged')){
            this.findHtmlForArticle(e.querySelector('#btn_merge'))
        }
    }
}
