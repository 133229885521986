import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["results", "form", "input"]

  connect() {
  }

  handleResults() {
    const [data, status, xhr] = event.detail
    this.resultsTarget.innerHTML = xhr.response
  }

  submit() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 200)
  }

  reset() {
    this.inputTarget.value = ''
    this.submit();
  }

}
