import { Controller } from 'stimulus'; 

    export default class extends Controller {
        static targets = ["addpresta"]

        connect(){}

        addpresta(){
            let divo = document.querySelector("#presta-div")
            let addPrestaContainer = document.createElement('div')
            addPrestaContainer.className = "add-presta-container p-4 rounded-xl bg-white bg-opacity-10 mt-4"
            addPrestaContainer.innerHTML = `
                <label class="string optional" for="temporary_contract_Quel sera le titre de ce nouvel article ">Décrivez la prestation à la charge du prestataire</label><br>
                <input type="text" name="temporary_contract[description_deliverableD][]" id="temporary_contract_description_deliverableD_" value="" multiple="multiple" class="w-full poppins block bg-dark-333 border-b-707 font-888 outline-none focus:border-purple-400"><br><br>

                <label class="string optional" for="temporary_contract_Avant quelle date cette prestation doit-elle être réalisée ">Avant quelle date cette prestation doit-elle être réalisée ?</label>
                <input label="false" class="poppins block bg-transparent border-b-707 font-888 string optional appearance-none mb-2 outline-none border-b-2 focus:border-purple-400" multiple="multiple" type="date" name="temporary_contract[date_deliverableD][]" id="temporary_contract_date_deliverableD">
                <input type="button" value="X" class="start-template-btn cursor-pointer w-8 h-8 poppins dark-f2f text-center" data-action="click->loi#removePresta">
            `
            divo.appendChild(addPrestaContainer)
        }

        removePresta(e){
            e.path[1].remove()
        }
    }
