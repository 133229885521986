import { Controller } from 'stimulus';
import $ from 'jquery'
import Rails from '@rails/ujs'

    export default class extends Controller {
        static targets = ['removeContribBtn','contribForm', 'formContainer']
        connect(){

        }



        renderNotice(notice){
            if(document.querySelector('#notice-js')){
              document.querySelector('#notice-js').remove()
            }
            document.querySelector("body").innerHTML += `
            <div class="fixed bottom-0 right-0 z-40" id="notice-js">
            <div class="alert px-8 py-2 rounded-tl-full flex justify-center align-center bg-green-500 alert-dismissible" role="alert">
              <p class="dark-f2f mr-2">${notice}</p>
              <button type="button" class="close dark-f2f" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        `
        if(document.querySelector('button[data-dismiss=alert]')){
          document.querySelector('button[data-dismiss=alert]').addEventListener('click', (e) => {
            document.querySelector('#notice-js').remove()
          })
        }

        setTimeout(() => {
          let animation = document.querySelector('.alert').animate({
            opacity: [1, 0]
          }, 1000)
          animation.onfinish = () => {
            document.querySelector('#notice-js').remove()
          }
        }, 5000);
        
        }

        renderAlert(alert){
            if(document.querySelector('#notice-js')){
              document.querySelector('#notice-js').remove()
            }
            document.querySelector("body").innerHTML += `
            <div class="fixed bottom-0 right-0 z-40" id="notice-js">
            <div class="alert px-8 py-2 rounded-tl-full flex justify-center align-center bg-red-500 alert-dismissible" role="alert">
              <p class="dark-f2f mr-2">${alert}</p>
              <button type="button" class="close dark-f2f" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        `
          if(document.querySelector('button[data-dismiss=alert]')){
            document.querySelector('button[data-dismiss=alert]').addEventListener('click', (e) => {
              document.querySelector('#notice-js').remove()
            })
          }

          setTimeout(() => {
            let animation = document.querySelector('.alert').animate({
              opacity: [1, 0]
            }, 1000)
            animation.onfinish = () => {
              document.querySelector('#notice-js').remove()
            }
          }, 5000);
          
        }

        addContrib(e){
          e.preventDefault()
          document.querySelector('.container').classList.remove('animate-bottom')
          const email = document.querySelector('#user_email').value
          const id = this.formContainerTarget.dataset.id
          const vid = this.formContainerTarget.dataset.vid
          $.ajax({
            method: "post",
            url: `/contracts/${id}/add_contributor`,
            data: {email: email},
            beforeSend: (xhr) => {xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken() )},
            success: (data) => {

              $(".submit-btn-container").html(`
              <button name="send_to_contributor" type="submit" form="form-contract" class="block  mr-4">
                  <div class="flex items-center start-template-btn px-4 py-2" style="cursor: pointer">
                      <p class="poppins dark-f2f mr-4 font-size-175">Transmettre</p>
                      <i class="far fa-paper-plane mr-2 dark-f2f"></i>
                  </div>
              </button>
              `)
              this.formContainerTarget.innerHTML = `
                <p class="poppins dark-purple text-center">Partager</p>
                  <div class="flex-grow">
                    <p class="w-10/12 py-2 ml-8 block px-2 poppins bg-transparent border-b-707 font-707 text-center my-4 md:my-0" id="contributor_email">${email}</p>
                  </div>
                  <a data-action="contributor#removeContrib" class="block" rel="nofollow" data-method="delete" href="/contracts/${id}/remove_contributor/${email}">
                  <div class="flex items-center start-template-btn px-4 py-2" style="cursor: pointer">
                    <p class="poppins dark-f2f mx-auto">Supprimer</p>
                  </div>
                </a>`

                this.renderNotice("l'ajout du Contributeur a réussi")

            },
            error: (data) => {
              this.renderAlert("Le Contributeur n'a pas pu etre ajoutée")
            }
          })
        }

        removeContrib(e){
          e.preventDefault();
          e.stopPropagation();
          document.querySelector('.container').classList.remove('animate-bottom')
          const email = document.querySelector('#contributor_email').innerHTML
          const id = this.formContainerTarget.dataset.id
          const vid = this.formContainerTarget.dataset.vid
          $.ajax({
            type: "DELETE",
            dataType: "json",
            url: `/contracts/${id}/remove_contributor/${email}`,
            beforeSend: (xhr) => {xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken() )},
            success: (data) => {
                $(".submit-btn-container").html(`<button name="finalize_contract" type="submit" form="form-contract" class="block mr-4" data-confirm="Etes-vous sûr de vouloir terminer votre contrat sans l'envoyer au préalable à un collaborateur ?">
                          <div class="flex items-center start-template-btn px-4 py-2" style="cursor: pointer">
                              <p class="poppins dark-f2f mr-4 font-size-175">Finaliser</p>
                              <i class="far fa-save dark-f2f font-size-175"></i>
                          </div>
                </button>`)
                this.formContainerTarget.innerHTML = `
                  <p class="poppins dark-purple text-center">Partager</p>
                  <div class="flex-grow">
                    <div class="input email required user_email" data-children-count="1"><input class="string email required w-10/12 py-2 ml-8 block px-2 poppins bg-transparent border-b-707 font-707 my-4 md:my-0" placeholder="exemple@gmail.com" type="email" value="" name="user[email]" id="user_email"></div>
                  </div>
                  <button name="add_contrib" type="submit" form="form-contrib" data-action="contributor#addContrib" class="block mx-auto">
                    <div class="flex items-center start-template-btn px-4 py-2" style="cursor: pointer">
                      <p class="poppins dark-f2f">Ajouter</p>
                    </div>
                  </button>`
                  this.renderNotice("la suppression du Contributeur a réussi")
            },
            error: (data) => {
              console.log(data)
            }
          })
        }
    }


//
