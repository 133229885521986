import { Controller } from 'stimulus'; 
import Trix from 'trix'
import $ from 'jquery'
export default class extends Controller {
    static targets = []
    connect(){
        if(!document.querySelector('.righting')){
            // Grab a reference to the toolbar(s) on the page.
            const toolbar = document.querySelector("#trix-toolbar-1")
            // Only apply event listeners once to the toolbars
            const sibling1 = toolbar.querySelector(".trix-button--icon-increase-nesting-level")
                sibling1.insertAdjacentHTML("afterend", '<button type="button" class="trix-button heading" data-trix-attribute="heading" title="Subheading"><i class="fas fa-underline px-4"></i></button>')
            const sibling3 = toolbar.querySelector("[data-trix-attribute='heading']")
                sibling3.insertAdjacentHTML("afterend", '<button type="button" class="trix-button righting" data-trix-attribute="righting" title="Subheading"><i class="fas fa-align-right px-4"></i></button>')
            const sibling2 = toolbar.querySelector("[data-trix-attribute='heading']")
                sibling2.insertAdjacentHTML("afterend", '<button type="button" class="trix-button centering" data-trix-attribute="centering" title="Subheading"><i class="fas fa-align-center px-4"></i></button>')
           
            // TODO BOUTON GAUCHE FOR SIMON
            // const sibling4 = toolbar.querySelector("[data-trix-attribute='heading']")
            //     sibling4.insertAdjacentHTML("afterend", '<button type="button" class="trix-button righting" data-trix-attribute="righting" title="Subheading"><i class="fas fa-align-right px-4"></i></button>')
        }
    }
} 

