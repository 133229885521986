import { Controller } from 'stimulus'; 

    export default class extends Controller {
        static targets = ["mission"]

        connect(){}


        addMission(){
            let addMissionContainer = document.createElement('div')
            addMissionContainer.className = "add-mission-container p-4 rounded-xl bg-white bg-opacity-10 mt-4"
            addMissionContainer.innerHTML = `
                <label class="string optional" for="temporary_contract_Définissez une nouvelle mission ">Définissez une nouvelle mission :</label><br>
                <input type="text" name="temporary_contract[article_amended_referenceM][]" class="w-full poppins block bg-dark-333 border-b-707 font-888 outline-none focus:border-purple-400" id="article_amended_referenceM_" value="" multiple="multiple"><br><br>
                <input type="button" value="X" class="start-template-btn cursor-pointer w-8 h-8 poppins dark-f2f text-center" data-action="click->delegation#removeMission">
            `
            this.missionTarget.appendChild(addMissionContainer)
        }

        removeMission(e){
            e.path[1].remove()
        }
    }
 
