import { Controller } from 'stimulus'; 

export default class extends Controller {
    connect(){

    }
    categoryBtn(e){
        document.querySelector('.clicked-category').classList.remove('clicked-category')
        e.target.classList.add('clicked-category')
        
        if(e.target.dataset.category != "all"){
            document.querySelectorAll(".category-item").forEach(item => {
                if(item.querySelector("p[data-category="+ e.target.dataset.category +"]")){
                    item.classList.remove('hidden')
                }else{
                    item.classList.add('hidden')
                }
            })
        }else if (e.target.dataset.category == "all"){
            document.querySelectorAll(".category-item").forEach(item => {
                if (item.querySelector('p[data-category]').dataset.category === "archived") {
                    item.classList.add('hidden')
                }else if (item.querySelector('p[data-category]').dataset.category !== "archived"){
                    item.classList.remove('hidden')
                }
            }) 
        }
    }

    searchBar(e){
        let value = e.target.value
        if(value == ""){
            document.querySelector('.clicked-category').classList.remove('clicked-category')
            document.querySelector('button[data-category=all]').classList.add('clicked-category')
        }
        let regex = new RegExp(`.*${value.toUpperCase()}.*`)
        document.querySelectorAll(".category-item").forEach(item => {
            if(item.querySelector('#category-name').innerText.toUpperCase().match(regex)){
                item.classList.remove('hidden')
                if(value == "" && item.querySelector('p[data-category]').dataset.category === "archived") {
                    item.classList.add('hidden')
                }else{
                    item.classList.remove('hidden')
                }
            }else{
                item.classList.add('hidden')
            }
        })
    }
} 




// Faire fonctionner la Btn bar
// il doit contenir plusieur boutton contenant un data-action categoryBtn
// et une data-target du nom de la BDD de la category correspondant


// pour afficher les item 
// on doit ajouter une classe category-item sur la premier div de l'element
// un data-category contenant le nom de la category de la BDD


// pour la search bar 
// ajouter un id category-name sur l'elemnt contenant le nom de l'item