import { Controller } from 'stimulus';
import $ from 'jquery'
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ["cookiesBanner","termsAndConditions", "termsWarning"]
    connect(){
    }

    terms(e){
        if (this.termsAndConditionsTarget.checked == false) {
            e.preventDefault()
            if(document.querySelector(".terms-warning") == undefined){
                const p = document.createElement('p');
                p.innerHTML = "Veuillez accepter les conditions générales d'utilisation Licorner";
                p.classList.add("text-red-500", "terms-warning")
                this.termsAndConditionsTarget.insertAdjacentElement('beforebegin', p)
            }
        }
    }

    cookies(e){
        $.ajax({
            method: "post",
            url:"/accept_cookies",
            beforeSend: (xhr) => {xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken() )},
            success: (data) => {
                // on succes hide cookie consent banner
                this.cookiesBannerTarget.classList.add("hidden")
            },
            error: (data) => {
                console.log(data.responseText)
            }
        })
    }
}
