import { Controller } from 'stimulus'; 
import $ from 'jquery'
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ['contractViewer', 'archivedQuestion', 'questionContainer', 'submitLawyerResponse','lawyersResponses', 'lawyerQuestion', "form", "QuestionBtn", "ModelsBtn", "modelsContainerBtn", "questionContainerBtn", "questionInfo", "VbModelShow"]
    connect(){
        for(let j = 0;  j < this.lawyerQuestionTargets.length; j ++){
            this.lawyerQuestionTargets[j].addEventListener('click', (e) => {
                if(document.querySelector('.active-item')){
                    document.querySelector('.active-item').classList.remove('active-item')
                }
                this.lawyerQuestionTargets[j].classList.add('active-item')
                this.question(e, this.lawyerQuestionTargets[j])
            })
            }
        }

    question(e, lawyerQuestion){
        e.preventDefault();
        e.stopPropagation();
        $.ajax({
            // on  question clique get question content
            method: "get",
            dataType: "json",
            url:  "/questions/" + lawyerQuestion.dataset.id,
            success:(data) => {
                // return data to front
                // this.returnData(data)
                this.returnData(data)
                },
            error: (data) => {
                console.log("ERROR", data) 
                }
        })
    }
    
    returnData(data){
        this.questionContainerTarget.innerHTML = ""
        this.questionContainerTarget.innerHTML = data.question.content
        this.questionContainerTarget.parentElement.querySelector('#discussion-email-client').innerText = `Discussion avec ${data.email}`
        this.questionContainerTarget.parentElement.querySelector('#discussion-email-client').dataset.email = data.email
        this.questionContainerTarget.parentElement.querySelector('#phone-number-popup').dataset.phone = data.phone_number
        this.archivedQuestionTarget.dataset.question = data.question.id
        document.querySelector('#_lawyers_question_id').value = data.question.id
        this.formTarget.action = `http://localhost:3000/questions/${ data.question.id}/responses`
        // TODO EN PROD CHANGER LOCALHOST:3000
        if(data.contract_path){
            this.contractViewerTarget.href = data.contract_path
        }
        if(data.lawyer_response){
            data.lawyer_response.forEach(el => {
                this.questionContainerTarget.insertAdjacentHTML('beforeend', `<span class="block border-solid border-t-2 border-888 w-full my-4 "></span>`)
                let divContent = document.createElement('div')
                divContent.classList.add('text-right', 'font-888')
                divContent.innerText = el.content
                this.questionContainerTarget.insertAdjacentElement('beforeend', divContent)
            })
        }
    }
    
    send(e){
        e.preventDefault()
        let formData = this.lawyersResponsesTarget.value
        $.ajax({
            method: "post",
            data: {lawyerResponse: formData }, 
            beforeSend: (xhr) => {xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken() )},
            url: this.formTarget.action,
            success: (data) => {
                this.questionContainerTarget.insertAdjacentHTML('beforeend', `<span class="block border-solid border-t-2 border-888 w-full my-4 "></span>`)
                let divContent = document.createElement('div')
                divContent.classList.add('text-right', 'font-888')
                divContent.innerText = formData
                this.questionContainerTarget.insertAdjacentElement('beforeend', divContent)
            },
            error: (data) => {
                console.log("ERROR", data.responseText) 
            }
        })
    }

    categoryCardLawyerBtn(e){
        let allBtn = [this.QuestionBtnTarget, this.ModelsBtnTarget]
        let currentTarget = undefined
        let container = undefined
        if(e.target.classList.contains("btn")){currentTarget = e.target.parentElement} else {currentTarget = e.target}
        if(currentTarget.dataset.container === "modelsContainerBtn"){
            this.formTarget.classList.add("hidden")
            this.questionInfoTarget.classList.add('hidden')
        }else{
            this.formTarget.classList.remove("hidden")
            this.questionInfoTarget.classList.remove('hidden')
        }
        allBtn.forEach(btn => {
            if(btn == currentTarget){
                this.targets.find(currentTarget.dataset.container).classList.remove("hidden")
            }else{
                this.targets.find(btn.dataset.container).classList.add("hidden")
            }
        });
    }

    showModels(e){
        let modelTarget
        if(e.target.classList.contains("lawyer-model")){modelTarget = e.target } else {modelTarget = e.target.parentElement}
        $.ajax({
            // on  question clique get question content
            method: "get",
            dataType: "json",
            url:  "/lawyers/models/" + modelTarget.dataset.id,
            success:(data) => {
                
                let vbModel = this.VbModelShowTarget
                vbModel.innerHTML = ""
                data.vblock.forEach(el => {
                    let vdiv = document.createElement("div")
                    vdiv.classList.add("p-4", "my-4")
                    let name = document.createElement("h2")
                    name.classList.add('block', "break-words", "mb-4", "text-justify")
                    name.innerText = el.name
                    let content = document.createElement("p")
                    content.classList.add("my-2", "leading-7", "text-justify")
                    content.innerText = el.content.replace( /(<([^>]+)>)/ig, '')
                    let span = document.createElement('span')
                    span.classList.add("block", "border-solid", "border-t-2", "border-gray-500", "w-full", "mt-4")
                    vdiv.appendChild(name)
                    vdiv.appendChild(content)
                    vdiv.appendChild(span)
                    vbModel.appendChild(vdiv)
                })
            },
            error: (data) => {
                console.log("ERROR", data) 
            }
        })
    }

    archived(){
        if(this.archivedQuestionTarget.dataset.question != ""){
            let confirmArchived= confirm("Are you sure you want to archive?");
            if(confirmArchived){
                $.ajax({
                    method: "post",
                    dataType: "json",
                    beforeSend: (xhr) => {xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken() )},
                    // take currentQuestion id and archived it
                    url: `/questions/${this.archivedQuestionTarget.dataset.question}/archived`,
                    success: (data) => {},
                    error: (data) => {
                        console.log("ERROR", data.responseText) 
                    }
                })
            }
        }
    }
}
