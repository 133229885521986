import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ["categoryItems", "pending", "contributor", "signed", "archived"]

    connect() {
    }

    toggleAll(event) {
      this.displayElements(this.categoryItemsTargets, event.currentTarget)
    }

    togglePending(event) {
      this.displayElements(this.pendingTargets, event.currentTarget)
    }

    toggleContributor(event) {
      this.displayElements(this.contributorTargets, event.currentTarget)
    }

    toggleSigned(event) {
      this.displayElements(this.signedTargets, event.currentTarget)
    }

    toggleArchived(event) {
      this.displayElements(this.archivedTargets, event.currentTarget)
    }

    toggleSwitchedCategoryButton(element) {
      document.querySelector('.clicked-category').classList.remove('clicked-category')
      element.classList.add('clicked-category')
    }

    displayElements(elementsToDisplay, categoryButton) {
      this.toggleSwitchedCategoryButton(categoryButton)
      const categoryItems = this.categoryItemsTargets
      if (categoryItems.length != elementsToDisplay.lenght) {
        for (const item of categoryItems) {
          item.classList.add("hidden");
        }
      }
      if (elementsToDisplay.lenght != 0) {
        for (const element of elementsToDisplay) {
          element.classList.remove("hidden");
        }
      }
    }

    changeDisplay(e){
        $.ajax({
            // on  question clique get question content
            method: "get",
            dataType: "json",
            url:  `/users/${e.target.dataset.id}/display` ,
            success:(data) => {
                location.reload(); 
                },
            error: (data) => {
                console.log(data.responseText)
            }
        })
    }

}
